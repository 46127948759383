import React, { useState, useEffect } from "react"
import styled from "styled-components"
import FlipbaseCard from "../../FlipbaseCard"

import { Col, Row } from "antd"
import { device, colors } from "../../../styles/constants"
import DotsGroup from "../../DecorationElements/DotsGroup"
import { Link } from "gatsby"

/* Card Row from the Blog listing*/

class BlogRow extends React.Component {
  render() {
    const cardsData = this.props.data.edges

    return (
      <ContentWrapper>
        <StyledRow gutter={24}>
          <DotsGroup quantity={40} left="-5%" top="5%" type="small" />
          {cardsData.map(card => {
            return (
              <StyledCol xl={6} lg={6} md={12} sm={12} xs={24}>
                <Link to={card.node.fields.slug}>
                  <FlipbaseCard type="Image" maxWidth="347px" data={card}  />
                </Link>
              </StyledCol>
            )
          })}
        </StyledRow>
      </ContentWrapper>
    )
  }
}

const ContentWrapper = styled.div`
  width: 100%;
  margin: 24px 0 32px 0;
  @media ${device.tablet} {
    margin: 32px 0;
    display: flex;
    flex-direction: column;
  }
  @media ${device.mobileL} {
    margin: 32px 0 32px;
  }
`

const StyledCol = styled(Col)`
  margin-bottom: 29px;
  @media ${device.tablet} {
    padding-left: 30px !important;
    padding-right: 30px !important;
    div {
      margin: 0 auto;
    }
  }
  @media ${device.mobileL} {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
`

const StyledRow = styled(Row)`
  width: 100%;
  @media ${device.tablet} {
    margin: 0 !important;
  }
`

export default BlogRow
