import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { GatsbyLocation } from "local-types"
import { device, colors } from "../../../styles/constants"

import { Link } from "gatsby"

/* Category List from Blog listing */

export interface CategoryList {
  location?: GatsbyLocation,
  listingType: string,
  categoryList: Array,
}

const CategoryList: React.FunctionComponent<CategoryList> = ({
  location,
  categoryList,
  listingType,
}): React.ReactElement => {
    const activeBlog =  /[blog]+[\/]+[0-9]/igm
    const foundBlog = location.pathname.match(activeBlog)
    return (
      <CategoryWrapper>
        <CategoryContent>
          <ScrollWrapper>
            <CategoryItem
              to={`${listingType}`}
              className={(foundBlog !== null && "active")}
              activeClassName="active"
            >
              Show all
            </CategoryItem>
            {categoryList.map(category => {
              return (
                category.toLowerCase() != "uncategorized" && (
                  <CategoryItem
                    key={category.toLowerCase()}
                    partiallyActive={true}
                    to={`${listingType}/${category.toLowerCase()}`}
                    activeClassName="active"
                  >
                    {category}
                  </CategoryItem>
                )
              )
            })}
          </ScrollWrapper>
        </CategoryContent>
      </CategoryWrapper>
    )
}

export default CategoryList

export const CategoryWrapper = styled.div`
  text-align: center;
  padding-top: 48px;
  margin-bottom: 0.5em;
  @media ${device.laptop} {
    padding-top: 12px;
  }
  @media ${device.mobileL} {
    margin-bottom: -24px;
  }
`
export const CategoryContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media ${device.laptop} {
    padding: 24px 30px;
    justify-content: space-between;
  }
  @media ${device.tablet} {
    padding: 16px 30px 0;
  }
  .active {
    color: ${colors.mainColor};
  }
  @media ${device.mobileL} {
    width: 100%;
    height: 100%;
    padding: 0;
    overflow-x: scroll;
    justify-content: flex-start;
    div {
      width: 300%;
      height: auto;
      a {
        width: 180px;
        :nth-of-type(2) {
          width: 180px;
        }
        :nth-of-type(1) {
          width: 80px;
        }
        :nth-of-type(5) {
          width: 130px;
        }
        :nth-of-type(4) {
          width: 170px;
        }
      }
    }
  }
`

export const CategoryItem = styled(Link)`
  color: ${colors.dark};
  font-family: FreightSans;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 60px;
  margin: 0 20px;
  text-transform: uppercase;
  :hover {
    color: ${colors.mainColor};
  }
  @media ${device.tablet} {
    margin: 0;
  }
`

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media ${device.laptop} {
    padding: 24px 0;
    justify-content: space-between;
  }
  @media ${device.tablet} {
    margin: 0;
    padding-bottom: 0;
    justify-content: space-between;
  }
  @media ${device.mobileL} {
    padding: 0 30px;
  }
`


