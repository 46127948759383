import React from "react"
import {Col} from 'antd'
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { device, colors } from "../../styles/constants";

const Pagination = ({ numPages, currentPage, contextPage, marginBottom, slugPrefix }) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? `${slugPrefix}` : `${slugPrefix}/` + (currentPage - 1).toString()
  const nextPage = `${slugPrefix}/` + (currentPage + 1).toString()
  if (numPages <= 1) {
    return null
  }

  const createPaginationObjects = (length, page, increment = 2) =>
    Array.from({ length }, (_, i) => ({
      link: `${slugPrefix}/${i + increment}/`,
      index: i + increment,
      current: page === i + increment,
    }));

  let navItems = [
    {
      link: `${slugPrefix}/`,
      index: 1,
      current: currentPage === isFirst,
    },
  ];
  if (numPages <= 5) {
    navItems = [
      ...navItems,
      ...Array.from({ length: numPages - 1 }, (_, i) => ({
        link: `${slugPrefix}/${i + 2}/`,
        index: i + 2,
        current: currentPage === i + 2,
      })),
    ];
  } else {
    if (currentPage <= 3) {
      navItems = [
        ...navItems,
        ...createPaginationObjects(3, currentPage),
        {
          separator: true,
          index: 'starter-separator',
        },
        {
          link: `${slugPrefix}/${numPages}/`,
          index: numPages,
          current: false,
        },
      ];
    } else if (currentPage > numPages - 3) {
      // If the current one is closer to the last one
      navItems = [
        ...navItems,
        {
          separator: true,
          index: 'finisher-separator',
        },
        ...createPaginationObjects(4, currentPage, numPages - 3),
      ];
    } else {
      navItems = [
        ...navItems,
        {
          separator: true,
          index: 'starter-separator',
        },
        ...createPaginationObjects(3, currentPage, currentPage - 1),
        {
          separator: true,
          index: 'finisher-separator',
        },
        {
          link: `${slugPrefix}/${numPages}/`,
          index: numPages,
          current: false,
        },
      ];
    }
  }
  
  return (
    <PaginationList marginBottom={marginBottom}>
      {!isFirst && (
        <Link to={prevPage}>
          <NextButton>
            <FaChevronLeft size={12}/>
          </NextButton>
        </Link>
      )}
      {navItems.map((item, i) => (
        <PaginationList>
          {item.separator ? (
            
            <PaginationSeparator>...</PaginationSeparator>
            
          ) : (
            <PaginationItem current={currentPage === item.index} key={i}>
              {currentPage === item.index ? (
                <span>{item.index}</span>
              ) : (
                <Link to={item.link}>{item.index}</Link>
              )}
            </PaginationItem>
          )}
        </PaginationList>
      ))}
      {!isLast && (
        <Link to={nextPage}>
          <NextButton>
            <FaChevronRight size={12}/>
          </NextButton>
        </Link>
      )}
    </PaginationList>
  )
}


export default Pagination


Pagination.propTypes = {
  marginBottom: PropTypes.string,
  slugPrefix: PropTypes.string
}

Pagination.defaultProps = {
  marginBottom: "0",
  slugPrefix: '/blog'
}

export const PaginationList = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  margin-bottom:${props => props.marginBottom};
  padding-left:0 !important;
  margin-left:0 !important;
  @media (max-width: 576px) {
    height: 36px;
    margin-bottom:72px;
  }
  
`

export const PaginationItem = styled.li`
  min-width: 38px;
  height: 38px;
  font-weight: 400;
  margin: 0 5px;
  text-align: center;
  border-top: 1ps solid transparent;
  text-decoration: none;
  font-family:Poppins;
  font-size:16px;
  line-height:24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:8px;
  background-color: rgba(35,29,48,0.05);
  @media (max-width: 576px) {
    min-width: 36px;
    height: 36px;
    margin:0 4px;
  }

  
  a {
    color:rgba(35,29,48,0.6) ;
    text-decoration: none;
    box-shadow: none;
    text-shadow:none;
    padding:8px 16px;
  }
  a: hover {
    color:rgba(35,29,48,0.6);
    text-decoration: none;
    box-shadow: none;
  }
  ${({ current }) =>
    current &&
    css`
      background-color: ${colors.mainLight};
      color: ${colors.mainColor};
      a {
      }
    `}
`

export const NextButton = styled.li`
min-width: 38px;
height: 38px;
font-weight: 400;
margin: 0 5px;
text-align: center;
border-top: 1ps solid transparent;
text-decoration: none;
font-family:Poppins;
font-size:16px;
line-height:24px;
background-color: rgba(35,29,48,0.05);
border-radius:8px;
align-items: center;
justify-content: center;
display: flex;
  @media (max-width: 576px) {
    min-width: 36px;
    height: 36px;
    margin:0 4px;
  }
  svg {
    color:rgba(35,29,48,0.6);
    text-decoration: none;
    box-shadow: none;
  }
  svg: hover {
    color:rgba(35,29,48,0.6);
    text-decoration: none;
    box-shadow: none;
  }
`

export const PaginationSeparator = styled.li`
min-width: 19px;
min-height: 48px;
font-weight: 400;
margin:0 5px;
padding-bottom:5px;
text-align: center;
border-top: 1ps solid transparent;
text-decoration: none;
font-family: Poppins;
font-size: 22px;
letter-spacing:0.1em;
display: flex;
align-items: center;
justify-content: center;
color: rgba(35,29,48,0.6);
@media (max-width: 576px) {
  margin:0;
}
`
